import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { createProjectSlides } from '../../utils'

import {
  Layout,
  BannerSmall,
  // ProjectSlider,
  TextBox,
  ProjectOverview,
  VideoAndText,
  BannerLinks,
  Brands
} from '../../components'

import Berufsreise from '../../assets/brands/berufsreise.png'
import Colompac from '../../assets/brands/colompac.png'
import Dikb from '../../assets/brands/dibk.png'
import Tiwag from '../../assets/brands/tiwag.png'
import WerHilftWie from '../../assets/brands/werhilftwie.png'
import Eglo from '../../assets/brands/eglo.png'
import EnergieTirol from '../../assets/brands/energie.tirol.png'
import HallAg from '../../assets/brands/hall.ag.png'
import Hall from '../../assets/brands/hall.png'
import Ikb from '../../assets/brands/ikb.png'
import RauchMehl from '../../assets/brands/rauchmehl.png'
import Intercable from '../../assets/brands/intercable.png'
import Pan from '../../assets/brands/pan.png'
import Prinoth from '../../assets/brands/prinoth.png'
import Wedl from '../../assets/brands/wedl.png'
import GSK from '../../assets/brands/gsk.png'
import Senoplast from '../../assets/brands/senoplast.png'
import Senova from '../../assets/brands/senova.png'
import Alpinamed from '../../assets/brands/alpinamed.png'
import GebroPharma from '../../assets/brands/gebropharma.png'
import Stubai from '../../assets/brands/stubai.png'
import Zellparts from '../../assets/brands/zellparts.png'
import ArbeiterKammer from '../../assets/brands/ak.png'
import Wirtschaftskammer from '../../assets/brands/wkohs.png'
import OmegaPharma from '../../assets/brands/omegapharma.png'
import BioHeizwerk from '../../assets/brands/bioheizwerk.png'
import Morf from '../../assets/brands/morf.png'
import NHT from '../../assets/brands/nht.png'
import Alupress from '../../assets/brands/alupress.png'

const brands = [
  {
    image: Berufsreise,
    alt: 'Berufsreise',
    link: '/projekte/berufsreise'
  },
  {
    image: Dikb,
    alt: 'Diözese Innsbruck',
    link: '/projekte'
  },
  {
    image: Colompac,
    alt: 'Colompac',
    link: '/projekte'
  },
  {
    image: Tiwag,
    alt: 'Tiwag',
    link: '/projekte'
  },
  {
    image: WerHilftWie,
    alt: 'WerHilftWie',
    link: '/projekte/werhilftwie'
  },
  {
    image: Eglo,
    alt: 'EGLO Leuchten',
    link: '/projekte/eglo-pim'
  },
  {
    image: EnergieTirol,
    alt: 'Energie Tirol',
    link: '/projekte'
  },
  {
    image: HallAg,
    alt: 'Hall AG',
    link: '/projekte'
  },
  {
    image: Hall,
    alt: 'Hall in Tirol',
    link: '/projekte/stadtamthall'
  },
  {
    image: Ikb,
    alt: 'Innsbrucker Kommunalbetriebe',
    link: '/projekte/ikb'
  },
  {
    image: Intercable,
    alt: 'Intercable',
    link: '/projekte/intercable'
  },
  {
    image: RauchMehl,
    alt: 'RauchMehl',
    link: '/projekte/rauchmehl'
  },
  {
    image: Pan,
    alt: 'Pan',
    link: '/projekte'
  },
  {
    image: Prinoth,
    alt: 'Prinoth',
    link: '/projekte'
  },
  {
    image: Wedl,
    alt: 'Wedl',
    link: '/projekte/wedl'
  },
  {
    image: GSK,
    alt: 'Haleon',
    link: '/projekte/haleon-gebro'
  },
  {
    image: Senoplast,
    alt: 'Senoplast',
    link: '/projekte/klepsch-group'
  },
  {
    image: Senova,
    alt: 'Senova',
    link: '/projekte/klepsch-group'
  },
  {
    image: Alpinamed,
    alt: 'Alpinamed',
    link: '/projekte'
  },
  {
    image: GebroPharma,
    alt: 'GebroPharma',
    link: '/projekte'
  },
  {
    image: Stubai,
    alt: 'Stubai',
    link: '/projekte'
  },
  {
    image: Zellparts,
    alt: 'Zellparts',
    link: '/projekte/klepsch-group'
  },
  {
    image: ArbeiterKammer,
    alt: 'AK',
    link: '/projekte/arbeiterkammer'
  },
  {
    image: Wirtschaftskammer,
    alt: 'WKO',
    link: '/projekte/wirtschaftskammer'
  },
  {
    image: OmegaPharma,
    alt: 'GebroPharma',
    link: '/projekte'
  },
  {
    image: BioHeizwerk,
    alt: 'BioHeizwerk',
    link: '/projekte'
  },
  {
    image: Morf,
    alt: 'Morf',
    link: '/hw-erp'
  },
  {
    image: NHT,
    alt: 'Neue Heimat Tirol',
    link: '/projekte/nht'
  },
  {
    image: Alupress,
    alt: 'Alupress',
    link: '/projekte/'
  }
]

import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/begeisterung.jpg'

// import berufsreise from '../../assets/projectslider/slider.berufsreise.groß.jpg'
import morf from '../../assets/projectslider/slider.morf.gross.jpg'
import ikb from '../../assets/projectslider/slider.ikb.groß.jpg'
import dibk from '../../assets/projectslider/slider.dibk.klein.jpg'
// import edelwatt from '../../assets/projectslider/slider.edelwatt.klein.jpg'
import werhilftwie from '../../assets/projectslider/slider.werhilftwie.klein.png'
import eglo from '../../assets/projectslider/slider.eglo.klein.jpg'
// import giesswein from '../../assets/projectslider/slider.giesswein.klein.jpg'
// import intercable from '../../assets/projectslider/slider.intercable.klein.jpg'
import ak from '../../assets/projectslider/slider.aktirol.klein.png'
import stadtamthall from '../../assets/projectslider/slider.stadtamthall.klein.png'
// import tiwag from '../../assets/projectslider/slider.tiwag.klein.jpg'
// import tyczka from '../../assets/projectslider/slider.tyczka.klein.jpg'
import voltadol from '../../assets/projectslider/slider.voltadol.klein.jpg'
import wedl from '../../assets/projectslider/slider.wedl.klein.jpg'
// import tq from '../../assets/projectslider/slider.tq.png'
import wko from '../../assets/projectslider/slider.wko.klein.jpeg'
import klepschgroup from '../../assets/projectslider/slider.klepschgroup.jpg'

const projects = createProjectSlides({
  bigImages: [
    {
      image: klepschgroup,
      text: (
        <span>
          <h2>Klepsch Gruppe</h2>
          <p>
            Das Multiportal der Klepsch Gruppe wurde dem State-of-the-Art des
            Webs angepasst und ein weiteres Portal für Zellparts wurde erstellt.{' '}
          </p>
        </span>
      ),
      link: '/projekte/klepsch-group'
    },
    {
      image: morf,
      text: (
        <span>
          <h2>MOMO - Ein Tool für alles</h2>
          <p>
            Projektverwaltung, Kundenverwaltung, Angebotslegung,
            Einsatzdisponierung, Rechnungserstellung, Mitarbeiterverwaltung,
            Aufwandserhebung - alles Bereiche die dieses hervorragende Tool
            abdeckt.
          </p>
        </span>
      ),
      link: '/projekte/morf'
    },
    {
      image: ikb,
      text: (
        <span>
          <h2>Von A wie Aufguss bis Z wie Zählerstand</h2>
          <p>
            Als kommunales Infrastruktur-Dienstleistungsunternehmen dient die
            Webseite der IKB vor allem als Informationsportal und somit als
            weiterer Service für ihre Kund:innen. Bei einer Webseite mit so vielen
            Inhalten wie auf ikb.at steht die Skalierbarkeit aus technischer
            Sicht an erster Stelle.
          </p>
        </span>
      ),
      link: '/projekte/ikb'
    }
  ],
  smallImages: [
    {
      image: dibk,
      text: (
        <span>
          <h2>Diözese Innsbruck Relaunch</h2>
          <p>
            Beim letzten kompletten Redesign 2016 realisierten wir ein
            hochkomplexes Mutliportalsystem, welches aus hunderten unabhängigen
            Internetauftritten besteht.
          </p>
        </span>
      ),
      link: '/projekte/dioezese'
    },
    {
      image: ak,
      text: (
        <span>
          <h2>Online mit Köpfchen</h2>
          <p>
            DAS Tool für die Durchführung von digitalen Workshops - inklusive
            Quiz von uns konzipiert und als Web-App umgesetzt.
          </p>
        </span>
      ),
      link: '/projekte/arbeiterkammer'
    },
    {
      image: wko,
      text: (
        <span>
          <h2>Die digitale Lernwelt</h2>
          <p>
            Mit der Lernplattform “Deine Lehre”, auf Open Source Basis und durch
            die Verwendung von Moodle und H5P, wird mit einfachen
            Lernmaterialien eine interaktive, digitale Lernwelt geschaffen.
          </p>
        </span>
      ),
      link: '/projekte/wirtschaftskammer'
    },
    {
      image: stadtamthall,
      text: (
        <span>
          <h2>Lebendiges Redesign - Stadtamthall</h2>
          <p>
            Die Bürgerthemen werden durch die neu gestaltete Website ordentlich
            aber lebendig dargestellt.
          </p>
        </span>
      ),
      link: '/projekte/stadtamthall'
    },
    {
      image: werhilftwie,
      text: (
        <span>
          <h2>Wer Hilft Wie Suchmaschine</h2>
          <p>
            Redesign der Wer Hilft Wie Suchmaschine. DIE Suchmaschine für
            Soziale Einrichtungen in Tirol. Die Adressen und Beschreibungen der
            Einrichtungen sind Kategorien und Suchbegriffen zugeordnet.
          </p>
        </span>
      ),
      link: '/projekte/werhilftwie'
    },
    {
      image: voltadol,
      text: (
        <span>
          <h2>Voltadol Portal</h2>
          <p>
            Eine Microsite mit Produktinformationen, Daten und Fakten zum
            Krankheitsbild und Tipps zur Vorsorge.
          </p>
        </span>
      ),
      link: 'https://www.voltadol.at'
    },
    {
      image: wedl,
      text: (
        <span>
          <h2>Wedl Handelshaus Relaunch</h2>
          <p>
            Der Fokus beim Relaunch lag auf einer kundenorientierten Website,
            die sich durch emotionale Bildsprache präsentiert.
          </p>
        </span>
      ),
      link: '/projekte/wedl'
    },
    {
      image: eglo,
      text: (
        <span>
          <h2>EGLO PIM</h2>
          <p>
            Gemeinsam mit unserem langjährigen Kunden EGLO wurde vor drei Jahren
            ein internes Produktinformationsmanagement (PIM) System für über
            240.000 Produkte ins Leben gerufen.{' '}
          </p>
        </span>
      ),
      link: '/projekte/eglo-pim'
    }
  ]
})

const Projects = () => {
  return (
    <Layout>
      <Helmet
        title="Referenzen der holzweg GmbH® / Wir lieben unsere Kund:innen!"
        meta={[
          {
            name: 'description',
            content:
              'Unsere Projekte reichen von e-commerce Lösungen, über state-of-the-art Webseiten bis hin zu komplexen Individualsoftware-Lösungen.'
          },
          {
            name: 'keywords',
            content:
              'projekte holzweg, design holzweg, strategie holzweg, technologie holzweg'
          },
          {
            property: 'og:description',
            content:
              'Unsere Projekte reichen von e-commerce Lösungen, über state-of-the-art Webseiten bis hin zu komplexen Individualsoftware-Lösungen.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/begeisterung-22251e3e1aa525dd3ee8381568cbd74c.jpg'
          }
        ]}
      ></Helmet>
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Unsere <nobr>Referenz</nobr>&#173;projekte
          </span>
        }
      />
      <TextBox
        title="Traumkunden? Gefunden."
        text="Willkommen in unserem umfangreichen Portfolio, das eine vielfältige Auswahl an Projekten umfasst, von maßgeschneiderten B2B-Shops bis hin zu beeindruckenden Wasserkraftwerksanimationen. Tauchen Sie ein und lassen Sie sich von unseren Referenzen inspirieren. Als erfahrene Webseiten- und Softwareentwickler stehen wir Ihnen zur Seite, um Ihre einzigartigen Visionen in die Realität umzusetzen. Vertrauen Sie auf unsere Expertise und lassen Sie uns gemeinsam herausragende Projekte entwickeln, die Ihre Erwartungen übertreffen. Kontaktieren Sie uns noch heute, um innovative Lösungen für Ihr Unternehmen zu entdecken."
      />
      <ProjectOverview />
      {/* <TextBox titleMods={['center']} title="Weitere Projekte" text="" /> */}
      {/* <ProjectSlider projects={projects} /> */}
      <Brands brands={brands} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Projects
